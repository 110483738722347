<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Offer Split Tests Overview">
      <template v-slot:actions>
        <q-btn
          label="New Split Test"
          to="/manage/offers/split-tests/create"
          color="primary"
          @click="
            $router.push({
              path: '/manage/offers/split-tests/create'
            })
          "
        />
      </template>
    </PageHeader>

    <DataTable
      table-key="offerSplitTestsOverview"
      row-key="id"
      title="Split Tests"
      :columns="columns"
      :rows="splitTestsByOffer"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
      :pagination="{
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 10
      }"
      :client-side-filter-method="filterMethod"
    >
      <template v-slot:name-append="row">
        <CopyToClipboardButton :copy-value="row.name" />
      </template>
      <template v-slot:split_parameters="row">
        <span
          v-for="splitTest in row.splitTests"
          :key="
            'splitParameter_' +
              splitTest.offer_id +
              splitTest.split_parameter_id
          "
        >
          <a href="javascript:void(0)" @click="testSplitParameterLink(splitTest.offer_id, splitTest.split_parameter)">{{ splitTest.split_parameter }}</a><br />
        </span>
      </template>
      <template v-slot:split_percentages="row">
        <span
          v-for="splitTest in row.splitTests"
          :key="
            'splitParameterSplitPercentage_' +
              splitTest.offer_id +
              splitTest.split_parameter_id
          "
        >
          {{ splitTest.split_percentage }}% <br />
        </span>
      </template>
      <template v-slot:days_active="row">
        <span
          v-for="splitTest in row.splitTests"
          :key="
            'splitParameterDaysActive_' +
              splitTest.offer_id +
              splitTest.split_parameter_id
          "
        >
          {{
            calculateDaysBetweenNow(
              !splitTest.time_updated
                ? splitTest.time_created
                : splitTest.time_updated
            )
          }}
          <small class="q-ml-md text-subtle">
            {{
              moment
                .utc(
                  !splitTest.time_updated
                    ? splitTest.time_created
                    : splitTest.time_updated
                )
                .local()
                .format("lll")
            }}
          </small>
          <br />
        </span>
      </template>
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () =>
                $router.push({
                  path: '/manage/offers/split-tests/edit/' + row.id
                }),
              label: 'Edit'
            },
            {
              action: () => promptDeleteOfferSplitTests(row),
              label: 'Delete All'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import moment from "moment";
import { Dialog } from "quasar";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import CopyToClipboardButton from "@/components/UI/CopyToClipboardButton";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
import DataTable from "@/components/DataTable";
moment.locale("en");

export default {
  name: "OfferSplitTestsOverview",
  components: {
    DataTable,
    ActionsDropdownButton,
    CopyToClipboardButton,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      moment,
      loading: true,
      columns: [
        {
          name: "id",
          label: "Offer ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "name",
          label: "Offer Name",
          field: "name",
          sortable: true
        },
        {
          name: "brand_name",
          label: "Brand",
          field: row => this.offersById[row.id].brand_name,
          sortable: true
        },
        {
          name: "split_parameters",
          label: "Split Parameters",
          field: "",
          sortable: false,
          filterable: false
        },
        {
          name: "split_percentages",
          label: "Split Percentages",
          field: "",
          sortable: false,
          filterable: false
        },
        {
          name: "days_active",
          label: "Days Active",
          field: "",
          sortable: false,
          filterable: false
        },
        {
          name: "actions",
          label: "",
          field: "",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  computed: {
    splitTestsByOffer() {
      return this.loading
        ? []
        : this.$store.getters["offers/splitTests/byOffer"];
    },
    offersById() {
      return this.$store.getters["offers/byId"];
    }
  },
  mounted() {
    this.$store.dispatch("offers/POPULATE_LIST").finally(() => {
      this.$store.dispatch("offers/splitTests/POPULATE_LIST").finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      });
    });
  },
  methods: {
    testSplitParameterLink(offerId, splitParameter) {
      if (!this.$store.getters["brands/isSet"]) {
        this.$store.dispatch("brands/POPULATE_LIST").finally(() => {
          window.open(this.generateSplitParameterTestLink(offerId, splitParameter), "_blank");
        });
      } else {
        window.open(this.generateSplitParameterTestLink(offerId, splitParameter), "_blank");
      }
    },
    generateSplitParameterTestLink(offerId, splitParameter) {
      let offer = this.$store.getters["offers/byId"][offerId];
      return (
          "https://" +
          this.$store.getters["brands/byId"][offer.brand_id]["subdomain"] +
          "/in.php?" +
          splitParameter
      );
    },
    promptDeleteOfferSplitTests(offer) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor: "all split tests associated with " + offer.name
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("offers/splitTests/POST_REQUEST", {
            params: {
              offer_id: offer.id,
              split_tests: []
            }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    filterMethod(rows, terms) {
      let lowerTerms = terms ? terms.toLowerCase() : "";
      return rows.filter(
        offer =>
          offer.id.toLowerCase().includes(lowerTerms) ||
          offer.name.toLowerCase().includes(lowerTerms) ||
          this.offersById[offer.id].brand_name
            .toLowerCase()
            .includes(lowerTerms) ||
          !!offer.splitTests.find(splitTest =>
            splitTest.split_parameter.toLowerCase().includes(lowerTerms)
          )
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
